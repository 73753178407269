.hello-player {
    width: 150px;
}
  
.dashboard-container {
    width: 80%;
    margin: auto;
    max-width: 600px;
    min-height: 600px;
}

.dashboard-score-container {
    display: flex;
    justify-content: space-between;
    padding: 8px 32px;
    background-color: #f4f4f4;
    border-radius: 20px;
    margin-bottom: 24px;
}

.dashboard-single-player-container {
    justify-content: flex-start;
    padding-top: 8px;
    padding-bottom: 16px;
    padding-right: 32px;
    background-color: #1961A815;
    border-radius: 20px;
    margin-bottom: 24px;
    flex-direction: column;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
}

.dashboard-team-survey-container {
    justify-content: flex-start;
    padding-top: 8px;
    padding-bottom: 16px;
    padding-left: 32px;
    background-color: #509E6F15;
    border-radius: 20px;
    margin-bottom: 24px;
    flex-direction: column;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
}

@media (max-width: 540px) {
    .dashboard-single-player-container {
        justify-content: center;
        padding-right: 16px;
        display: flex;
        align-items: center;
        flex-direction: column;
        flex-wrap: nowrap;
    }
}

.dashboard-text-container {
    flex-direction: column;
    margin-bottom: 8px;
}

.subtitle-text {
    color: gray;
    font-weight: 400;
}

.button-text {
    color: #1961A8;
    cursor: pointer;
    font-weight: 600;
    font-size: large;
    text-decoration: none;
}

.button-text:hover {
    color: #333333;
}

.dashboard-title {
    font-family: 'Fredoka';
    /* src: url("../fonts/Brandon_blk.otf"); */
    letter-spacing: 1.1px;
}

.dashboard-image {
    cursor: pointer;
    padding: 8px;
    transition: 0.2s cubic-bezier(0.44, 0.13, 0.48, 0.87);
}

.dashboard-image:hover {
    box-shadow: 6px 6px 20px 20px #00000010;
    border-radius: 20px;
}

@media (max-width: 500px) {
    .dashboard-image {
        width: auto;
    }
}