/* PLAYER CARD */
.player-card {
    background-color: #ffffff;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center center;
    width: 300px;
    min-width: 275px;
    max-width: 550px;
    aspect-ratio: .75;
    will-change: transform;
    border-radius: 30px;
    margin-bottom: 24px;
    position: relative;
    top: 0;
    transition: top ease 0.2s;
    cursor: pointer;
    cursor: url('../cursor.png') 15 15, auto;
}

.player-card-title {
    font-family: 'Fredoka';
    font-size: 18px;
    text-align: center;
    letter-spacing: 0.5px;
    width: 100%;
    font-weight: 600;
    margin-bottom: 2px;
}

.player-card-name {
    font-family: 'Fredoka';
    font-size: 32px;
    text-align: center;
    font-weight: 600;
    text-transform: lowercase;
}

.player-card-description {
    font-size: 11px;
    text-align: center;
    height: auto;
    margin-top: auto;
    padding-left: 4px;
    padding-right: 4px;
    color: gray;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
}