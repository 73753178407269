/* @font-face {
  font-family: 'Fredoka';
}

@font-face {
  font-family: 'Brandon';
  font-weight: 900;
  src: local('Brandon'), url(./fonts/Brandon_bld.otf) format('opentype');
} */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    width: 80%;
    max-width: 400px;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Fredoka-title {
  font-family: 'Fredoka', serif;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p, h1, h2, h3, h4 {
  font-family: 'Fredoka', serif;
}

.page-container {
  width: 80%;
  margin: auto;
  max-width: 800px;
  min-height: 900px;
}

.full-page-container {
  width: 80%;
  margin: auto;
  min-height: 900px;
}

.segment-div {
  display: flex;
  width: 100%;
  margin: auto;
  max-width: 600px;
}

.segment-item {
  font-family: 'Fredoka';
  padding: 12px;
  border-radius: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: 800;
  width: 100%;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  transition: 0.2s cubic-bezier(0.44, 0.13, 0.48, 0.87);
}

.segment-unselected {
  color: #000000;
  background-color: #f4f4f4;
  
}

.segment-unselected:hover {
  color: #f4f4f4;
  background-color: #505050;
}

.segment-selected {
  color: #FFFFFF;
  background-color: #000000;
}

.final-submit-btn {
  background-color: #D1DFEE;
  color: #68A6EB;
  text-align: center;
  font-family: 'Fredoka';
  font-weight: 600;
  font-size: 18px;
  padding: 16px;
  margin: auto;
  width: 90%;
  cursor: pointer;
  border-radius: 12px;
  text-decoration: none;
  display: block;
  transition: 0.2s cubic-bezier(0.44, 0.13, 0.48, 0.87);
}

.final-submit-btn:hover {
  background-color: #68A6EB;
  color: #D1DFEE;
}

.submit-btn {
  background-color: #D1DFEE;
  color: #68A6EB;
  text-align: center;
  font-weight: 600;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 80px;
  display: block;
  cursor: pointer;
  border-radius: 12px;
  transition: 0.2s cubic-bezier(0.44, 0.13, 0.48, 0.87);
}

.submit-btn:hover {
  background-color: #68A6EB;
  color: #D1DFEE;
}

.submit-btn-active {
  background-color: #68A6EB;
  color: #D1DFEE;
  text-align: center;
  font-weight: 600;
  padding: 16px;
  cursor: pointer;
  border-radius: 12px;
  transition: 0.2s cubic-bezier(0.44, 0.13, 0.48, 0.87);
}

.inline-btn {
  color: #1961a8;
  cursor: pointer;
  font-weight: 600;
  margin: auto 0;
  text-decoration: none;
  transition: .2s cubic-bezier(.44,.13,.48,.87);
}

.inline-btn:hover {
  color: #333333;
}

.inline-blue-btn {
  color: #68A6EB;
}

.inline-blue-btn:hover {
  color: #D1DFEE;
}

.inline-blue-btn-dash {
  color: #68A6EB;
}

.inline-blue-btn-dash:hover {
  color: #68A6EB;
}

.inline-red-btn {
  color: #CA392D;
}

.inline-red-btn:hover {
  color: #F4DDDA;
}

.inline-green-btn {
  color: #509E6F;
}

.inline-green-btn:hover {
  color: #DCECE2;
}

.inline-orange-btn {
  color: #ED6742;
}

.inline-orange-btn:hover {
  color: #FBE1D9;
}

.blue-btn {
  background-color: #D1DFEE;
  color: #1961A8;
}

.blue-btn:hover {
  background-color: #68A6EB;
  color: #D1DFEE;
}

.blue-btn-active {
  background-color: #68A6EB;
  color: #D1DFEE;
}

.blue-btn-active:hover {
  background-color: #D1DFEE;
  color: #68A6EB;
}

.blue-btn-dash {
  background-color: #68A6EB;
  color: white;
}

.blue-btn-dash:hover {
  background-color: #3E87DF;
  color: white;
}

.red-btn {
  background-color: #F4DDDA;
  color: #CA392D;
}

.red-btn-dash {
  background-color: #CA392D;
  color: white;
}

.red-btn-active {
  background-color: #CA392D;
  color: #F4DDDA;
}

.red-btn:hover {
  background-color: #CA392D;
  color: #F4DDDA;
}

.red-btn-active:hover {
  background-color: #CA392D;
  color: #F4DDDA;
}

.orange-btn {
  background-color: #FBE1D9;
  color: #ED6742;
}

.orange-btn-active {
  background-color: #ED6742;
  color: #FBE1D9;
}

.orange-btn:hover {
  background-color: #ED6742;
  color: #FBE1D9;
}

.orange-btn-active:hover {
  background-color: #ED6742;
  color: #FBE1D9;
}

.yellow-btn {
  background-color: #FFF3D7;
  color: #FFC337;
}

.yellow-btn-active {
  background-color: #FFC337;
  color: #FFF3D7;
}

.yellow-btn-active:hover {
  background-color: #FFC337;
  color: #FFF3D7;
}

.yellow-btn:hover {
  background-color: #FFC337;
  color: #FFF3D7;
}

.green-btn {
  background-color: #DCECE2;
  color: #409F91;
}

.green-btn:hover {
  background-color: #409F91;
  color: #DCECE2;
}

.green-btn-dash {
  background-color: #409F91;
  color: white;
}

.green-btn-dash:hover {
  background-color: #3A8578;
  color: white;
}

.green-btn-active {
  background-color: #409F91;
  color: #DCECE2;
}

.green-btn-active:hover {
  background-color: #409F91;
  color: #DCECE2;
}

.gray-btn {
  background-color: #dcdcdc;
  color: #4e4f4e;
}

.gray-btn:hover {
  background-color: #000000;
  color: #dcdcdc;
}

.gray-btn-active {
  background-color: #000000;
  color: #dcdcdc;
}

.gray-btn-active:hover {
  background-color: #000000;
  color: #dcdcdc;
}

.gold-btn {
  background-color: #FCF0D5;
  color: #df9b00;
}

.gold-btn-active {
  background-color: #df9b00;
  color: #FCF0D5;
}

.gold-btn-active:hover {
  background-color: #df9b00;
  color: #FCF0D5;
}

.gold-btn:hover {
  background-color: #df9b00;
  color: #FCF0D5;
}

.gold-btn-dash {
  background-color: #df9b00;
  color: white;
}

/* DASHBOARD */

.dashboard-tag-center {
  font-family: 'Fredoka';
  margin: auto;
  width: fit-content;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
  background: #00000024;
  color: #268fff;
  font-size: 12px;
}

.dashboard-tag-left {
  font-family: 'Fredoka';
  margin: auto auto auto 0;
  width: fit-content;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
  background: #00000024;
  color: #268fff;
  font-size: 12px;
}

.dashboard-tag-right {
  font-family: 'Fredoka';
  margin: auto 0 auto auto;
  width: fit-content;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
  background: #00000024;
  color: #268fff;
  font-size: 12px;
}

.dashboard-section-title {
  text-align: center;
  font-family: 'Fredoka';
  font-weight: 600;
  font-size: 48px;
  margin: 16px 0;
  text-transform: lowercase;
  color: white;
}

.dashboard-section-subtitle {
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  margin: 0px 0px 24px 0px;
  color: #dddddd;
}

/* OVERLAY */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.overlay-content {
  background: #fff;
  padding: 20px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  border-radius: 8px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.scrollable-content {
  max-height: 100%;
  overflow-y: auto;
}

.play-card-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.play-card-overlay-content {
  background: #fff;
  padding: 20px;
  width: 80%;
  max-height: 80%;
  overflow-y: auto;
  border-radius: 8px;
  position: relative;
}

.card-title {
  font-family: 'Fredoka';
  font-size: 15px;
  text-align: center;
  letter-spacing: 0.5px;
  width: 100%;
  font-weight: 800;
  margin-bottom: 2px;
}